[dir] .card-strategic[data-v-fe22c3ea] {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.07);
  border-radius: 0.428rem;
  border: 1px solid #f3f3f3;
}
.btn-trash[data-v-fe22c3ea] {
  height: 44px;
}
[dir] .btn-trash[data-v-fe22c3ea] {
  text-align: center;
  padding: 0px !important;
}